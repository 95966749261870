type PartnerContact = {
  telefone?: string | string[];
  email?: string;
  site?: string;
  ouvidoria?: string | string[];
  sac?: string | string[];
  whatsapp?: string;
  site_movel?: string;
  site_fixo?: string;
};

const partnersContact: { [key: string]: PartnerContact } = {
  asp: {
    telefone: "0800 142 0579",
    email: "contato@asp-consultoria.com",
    site: "http://asp-consultoria.com/"
  },
  ativossa: {
    telefone: "0800-644-3030",
    email: "sac@ativossa.com.br",
    site: "www.ativossa.com.br/"
  },
  bancodobrasil: {
    telefone: ["4004 0001", "0800 729 0001"],
    ouvidoria: "0800 729 5678",
    sac: "0800 729 0722"
  },
  bradescard: {
    telefone: ["4004 1203", "0800 979 0203"],
    whatsapp: "(11) 98769 0143",
    sac: ["0800 730 5030", "0800 722 0099"],
    site: "bradescard.rcbserv.com.br/Home",
  },
  bradesco: {
    telefone: ["4002 0022", "0800 570 00 22"],
    whatsapp: "11 3335 0237",
    sac: "0800 704 8383",
    ouvidoria: "0800 727 9933",
    site: "banco.bradesco/inovacao/bia.shtm"
  },
  btg: {
    telefone: ["3003-6390", "0800-777-6390"],
    whatsapp: "(11) 3383-5095",
    site: "www.btgpactual.com/fale-conosco"
  },
  carrefour: {
    telefone: ["3004 2222", "0800 718 2222"],
    site: "www.carrefoursolucoes.com.br/"
  },
  "carrefour-atacadao": {
    telefone: ["(11) 3004-2222", "0800 718 22 22"],
    site: "app.cartaoatacadao.com.br/acordo-de-divida"
  },
  cielo: {
    telefone: ["4002 5472", "0800 570 8472"],
    whatsapp: "(11) 3003 2818",
    ouvidoria: ["4002 8288", "0800 570 2288"],
    site: "www.cielo.com.br/atendimento/"
  },
  claro: {
    telefone: "1052",
    ouvidoria: "0800 701 0180",
    site: "claronegocie.com.br/login"
  },
  credigy: {
    telefone: "0800 882 7810",
    email: "contato@crediativos.com.br",
    site: "crediativos.net/contato"
  },
  credsystem: {
    telefone: ["4003 3900", "0800 729 3900"],
    ouvidoria: "0800 777 5297",
    site: "www.portaldocartao.com.br/"
  },
  credz: {
    telefone: ["4003 4031", "0800 880 4031"],
    whatsapp: "(11) 4020-2119",
    site: "portal.credz.cm.br/atendimento/fale-conosco"
  },
  dacasa: {
    telefone: ["4002 3233", "0800 000 4418"],
    whatsapp: "(27) 99645-6979",
    email: "atendimento@dacasa.com.br",
  },
  "fundo-atlantico": {
    telefone: "0800 882 7810",
    email: "contato@crediativos.com.br",
    site: "crediativos.net/contato"
  },
  havan: {
    telefone: ["4020-8984", "0800 285 8984"],
    site: "negociacao.havan.com.br/",
  },
  hoepers: {
    telefone: "0800 727 3159",
    whatsapp: "(51) 2117 1992",
    email: "contato@hoepers.com",
    site: "institucional.hoepers.com/contato/"
  },
  ipanema: {
    telefone: "0800 025 0000",
    site: "negocieipanema.com.br/entrar"
  },
  itapeva: {
    telefone: "0800 770 5989",
    whatsapp: "(11) 2874-1477",
    email: "atendimento@itapevarec.com.br",
    site: "www.itapevarec.com.br/Nembus/Home"
  },
  itau: {
    telefone: ["4004 4828", "0800 970 4828"],
    sac: "0800 728 0728",
    whatsapp: "+55 11 4004-4828",
    site: "correspondenciasdigitais.itau.com.br/login"
  },
  anhanguera: {
    telefone: ["3003-4030", "3003-9344"],
    whatsapp: "(11) 91348-1040",
    site: "www.anhanguera.com/unidades/"
  },
  pitagoras: {
    telefone: ["3003-4030", "3003-9344"],
    whatsapp: "(11) 91348-1040",
    site: "www.pitagoras.com.br/unidades/"
  },
  unopar: {
    telefone: ["3003-4030", "3003-9344"],
    whatsapp: "(11) 91348-1035",
    site: "www.unopar.com.br/unidades/"
  },
  unic: {
    site: "www.unic.com.br/unidades/",
    telefone: ["3003-4030", "3003-9344"]
  },
  unime: {
    site: "www.unime.edu.br/unidades/",
    telefone: "3003-9344"
  },
  uniderp: {
    telefone: "3003-4030",
    whatsapp: "(11) 9 1314-0306",
    site: "www.uniderp.br/unidades/"
  },
  fama: {
    email: "contato@faculdadefama.edu.br",
    site: "faculdadefama.edu.br/fale-conosco/"
  },
  liftcred: {
    telefone: "0800 608 6666",
    whatsapp: "(11) 98768 1523",
    site: "liftcred.com.br/"
  },
  colombo: {
    whatsapp: "+55 800 642 4242",
    telefone: "0800 022 5817",
    site: "www.colombo.com.br/pagamento-colombo"
  },
  lojasrenner: {
    telefone: ["4004 2900", "0800 600 6601"],
    whatsapp: "51 3921 4004",
    sac: "0800 600 6601",
    site: "www.lojasrenner.com.br/nossas-lojas"
  },
  nubank: {
    telefone: ["4020 0185", "0800 591 2117"],
    email: "meajuda@nubank.com.br",
  },
  recovery: {
    site: "www.gruporecovery.com/",
    whatsapp: "(11) 4765 8402",
    telefone: "0800 772 3331"
  },
  riachuelo: {
    telefone: ["3004 5417", "0800 727 4417"],
    sac: "0800 721 3344",
    site: "www.midway.com.br/atendimento",
  },
  sky: {
    sac: "106 11",
    telefone: "0800 728 7160",
    whatsapp: "11 3003-1180",
    site: "www.sky.com.br/central-de-atendimento"
  },
  tricard: {
    telefone: ["3003 3099", "0800 722 3099"],
    sac: "0800 722 3091",
    whatsapp: "(11) 3003-3099",
    site: "websec.tricard.com.br/"
  },
  trigg: {
    telefone: "3003-1025",
    sac: "0800 727 0885",
    site: "www.trigg.com.br/",
    whatsapp: "11 97818-5713"
  },
  uzecartoes: {
    telefone: ["3004 0800", "0800 703 9391"],
    whatsapp: "71 99631-9755",
  },
  casasbahia: {
    telefone: ["0800-200-4336", "4003-4336"],
    whatsapp: "(21) 99502-7179",
    site: "renegocie.casasbahia.com.br/"
  },
  ponto: {
    telefone: ["0800-200-4336", "4003-4336"],
    whatsapp: "(21) 99502-7179",
    site: "renegocie.casasbahia.com.br/"
  },
  vivo: {
    telefone: ["10315", "1058"],
    site_movel: "www.vivo.com.br/para-voce/meu-vivo",
    site_fixo: "vivoemdia.vivo.com.br/#/"
  },
  neon: {
    whatsapp: "11 48619331",
    email: "oi@neon.com.br",
    site: "neon.com.br/atendimento-neon"
  },
  original: {
    sac: "0800 733 0733",
    ouvidoria: "0800 755 0755",
    site: "www.original.com.br/atendimento/"
  },
  c6bank: {
    sac: "0800 660 0060",
    ouvidoria: "0800 660 6060",
    whatsapp: "+55 11 2832-6088",
    site: "www.c6bank.com.br/"
  },
  dmcard: {
    sac: ["(12) 2136-0100", "0800 702 5004"],
    ouvidoria: "0800 727 8802",
    whatsapp: "(12) 2136-0100",
    site: "www.vocedm.com.br/portal/fale-conosco"
  },
  mercadopago: {
    telefone: "0800 637 7246",
    site: "www.mercadopago.com.br/ajuda"
  },
  omni: {
    telefone: ["4004-3500", "0800 701 3500"],
    whatsapp: "4004-3500",
    sac: "0800 727 0885",
    ouvidoria: "0800 701 0412"
  }
}

export default partnersContact;