import { SHAnnuity } from "../../../../../libs/sortinghat/src/lib/types";

type FormatHighlightProps = {
  description?: string;
  descriptionSlug?: string;
  partner?: string;
  annuity?: SHAnnuity;
};

export const formatHighlight = ({
  description,
  descriptionSlug,
  partner,
  annuity,
}: FormatHighlightProps) => {
  const isCaixa = partner === 'caixa';
  const isAnnuityDesc = descriptionSlug === 'zero_annuity';

  if (isCaixa && isAnnuityDesc && annuity) {
    const annuityText: any = {
      first_year_free: 'Anuidade grátis no 1° ano',
      always_free: 'Anuidade grátis',
    };

    const conditionalAnnuity = annuityText[annuity];

    if (conditionalAnnuity) {
      return conditionalAnnuity;
    } else {
      return annuity;
    }
  } else {
    return description;
  }
};
