import { useEffect, useRef, useState } from 'react';

import { dateToLocale } from '@libs/utils/helpers/dateToLocale';
import { currencyToLocale } from '@libs/utils/helpers/currencyToLocale';

import {
  SimulatedDebt,
  SimulatedPaymentOption,
  DebtBankAccount,
} from '@common/apis/simulated-debt/types';
import {
  getInfosCielo,
  getInstallmentDueDate,
} from '@common/hooks/useDebts/helpers';

import { useFormattedCetProps } from '@common/components/DebtsModal/Details/hooks/useFormattedCetProps';
import { DebtData } from '@common/hooks/useDebts/types';

interface UseConfirmAgreementDetails {
  debt: SimulatedDebt;
  paymentOption: SimulatedPaymentOption;

  debtData: DebtData;
  selectedBankAccount?: DebtBankAccount;
}

interface ItemDetail {
  title: string;
  desc: string | number;
}

export const useConfirmAgreementDetails = ({
  debt,
  paymentOption,

  debtData,
  selectedBankAccount,
}: UseConfirmAgreementDetails) => {
  const modalDetailsRef = useRef<any>(null);

  const [itemDetails, setItemDetails] = useState<ItemDetail[] | undefined>(
    undefined
  );

  const { isItau } = debtData.currentPartner || {};
  const {
    isDebitInAccountAndNotBradesco,
    hasDebitInAccountEntry,
    id: paymentId,
    debitInAccountRequired,
  } = debtData?.paymentMeth || {};

  const isDebitInAccSelected = paymentId === 'DEBIT_ACCOUNT';

  const currentPaymentIsDebitInAcc =
    (isDebitInAccSelected && isDebitInAccountAndNotBradesco) ||
    (isDebitInAccSelected && hasDebitInAccountEntry) ||
    (isItau && debitInAccountRequired);

  const clickOpenModal = () => {
    modalDetailsRef.current.open({
      debt,
      paymentOption,
      buttonText: 'Ok, entendi',
      closeModalOnClickCta: true,
    });
  };

  const setDateOfDebt = (dateOfDebt: string) => {
    if (!dateOfDebt) return [];

    return [{ title: 'Data da dívida', desc: dateToLocale(dateOfDebt) }];
  };

  const setNegotiationType = (negotiationType?: string) => {
    if (!negotiationType && negotiationType !== 'overdued_only') return [];

    return [{ title: 'Tipo de negociação', desc: 'Parcelas vencidas' }];
  };

  const setOriginalValueDebt = (originalValueDebt: number) => {
    if (!originalValueDebt) return [];

    return [
      {
        title: 'Valor original da dívida',
        desc: currencyToLocale(originalValueDebt),
      },
    ];
  };

  const setUpdatedDebtValue = (
    recalculated: boolean | undefined,
    currentDebtValue: number
  ) => {
    if (!recalculated) return [];

    return [
      {
        title: 'Valor atualizado da dívida',
        desc: currencyToLocale(currentDebtValue),
      },
    ];
  };

  const setContractNumber = (contract: string | number) => {
    if (!contract) return [];
    
    return [{ title: 'Número de contrato', desc: contract }];
  };

  const setFirstInstallmentDue = (simulationDate: string) => {
    if (!simulationDate) return [];

    let title = 'Vencimento';

    if (!debtData?.paymentOpt?.isSinglePayment) {
      title += debtData?.paymentOpt?.hasEntry
        ? ' da entrada'
        : ' da primeira parcela';
    }

    if (currentPaymentIsDebitInAcc) {
      const additionalDesc =
        isItau && paymentId === 'PIX' ? ' (No Pix)' : ' (No Boleto)';
      title += additionalDesc;
    }

    return [{ title, desc: dateToLocale(simulationDate) }];
  };

  const setRemainingInstallments = (
    parcelQty: number,
    properties: any[],
    simulationDate: string
  ) => {
    const isRiachuelo = debtData.partnerIdentifier === 'riachuelo';
    if (!parcelQty || !properties || !simulationDate || isRiachuelo) return [];

    const optionWithEntry = debtData?.paymentOpt?.hasEntry;
    let title = optionWithEntry
      ? 'Vencimento da parcela restante'
      : 'Vencimento da segunda parcela';

    if (parcelQty > 2) title = 'Vencimento das parcelas restantes';

    if (currentPaymentIsDebitInAcc) title += ' (Débito em Conta)';

    const installmentDueDate = getInstallmentDueDate(
      properties,
      simulationDate
    );
    let desc = `Dia ${installmentDueDate} ${
      parcelQty > 2 ? 'de cada mês' : ''
    }`;

    if (optionWithEntry) {
      desc += ' (Mês seguinte ao pagamento da entrada)';
    } else if (!optionWithEntry && parcelQty === 2) {
      desc += ' (Mês seguinte ao pagamento da primeira parcela)';
    }

    return parcelQty > 1 ? [{ title, desc }] : [];
  };

  const setTotalValueOfTheAgreement = (agreementValue: number) => {
    if (!agreementValue) return [];

    return [
      {
        title: 'Valor total do acordo',
        desc: currencyToLocale(agreementValue),
      },
    ];
  };

  const setBankAccountInfo = () => {
    const isDebitAcc = paymentId === 'DEBIT_ACCOUNT' || debitInAccountRequired;
    const hasSelectedDebitInAcc = !!selectedBankAccount && isDebitAcc;

    const DebitAccItem = [
      {
        title: 'Conta para débito automático',
        desc: `Agência: ${selectedBankAccount?.account} / Conta: ${selectedBankAccount?.agency}`,
      },
    ];

    return hasSelectedDebitInAcc ? DebitAccItem : [];
  };

  const handleItemDetails = () => {
    const {
      detalhes,
      contrato,
      dataSimulacao,
      valorDivida,
      propriedades,
      typeSelected,
    } = debt || {};
    const { currentDebtValue, valorAcordo, recalculated, qtdParcela } =
      paymentOption || {};

    const items = [
      ...setDateOfDebt(detalhes?.[0]?.dataOriginalDivida),
      ...setOriginalValueDebt(valorDivida),
      ...setUpdatedDebtValue(recalculated, currentDebtValue),
      ...setContractNumber(contrato),
      ...setFirstInstallmentDue(dataSimulacao), // << TODO: b
      ...setRemainingInstallments(qtdParcela, propriedades, dataSimulacao),
      ...setTotalValueOfTheAgreement(valorAcordo),
      ...setBankAccountInfo(),
      ...setNegotiationType(typeSelected),
    ];

    return items;
  };

  useEffect(() => setItemDetails(handleItemDetails()), [debtData]);

  return {
    itemDetails,

    isDebtComposition: debt?.detalhes?.length > 1,
    hasCetProps: useFormattedCetProps(paymentOption),
    hasInfosCielo: getInfosCielo(debt?.propriedades)?.hasPropertiesCielo,

    modalDetailsRef,
    clickOpenModal,
  };
};
