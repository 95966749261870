import { useImperativeHandle, useState } from 'react';

import constants from '@common/constants';
import { SimulatedDebt } from '@common/apis/simulated-debt/types';
import useSimulatedDebt from '@common/hooks/useSimulatedDebt';
import { legacyBase64Encrypt } from '@libs/utils/helpers/base64';
import { currencyToLocale } from '@libs/utils/helpers/currencyToLocale';
import { formatPercentage } from '@dues/pages/Partner/Confirmation/ConfirmationV2/components/DebtComposition/hooks/useFormattedCetProps';
import * as itau from '@dues/helpers/itau';
import { useRedline } from '@libs/redline';

interface OpenProps {
  debt: SimulatedDebt;
  goToInstallments: (paymentMethod: string) => void;
}

export interface ExpiredInstallmentsModalRef {
  open: (args: OpenProps) => void;
}

const useExpiredInstallments = (
  ref: React.ForwardedRef<ExpiredInstallmentsModalRef>
) => {
  const { checkExpiredInstallments } = useSimulatedDebt();

  const [currentDebt, setCurrentDebt] = useState<SimulatedDebt | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hasOverduedOnly, setHasOverduedOnly] = useState(false);
  const [hasOverduedAndUpcoming, setHasOverduedAndUpcoming] = useState(false);
  const [valueOrverdueOnly, setValueOrverdueOnly] = useState('');
  const [valueOverdueUpcoming, setValueOverdueUpcoming] = useState('');
  const { track } = useRedline();

  const initialPRADefaultValues = {
    overdueOnly: '',
    overdueUpcoming: '',
  };
  const [originalDebtValues, setOriginalDebtValues] = useState(
    initialPRADefaultValues
  );
  const [discounts, setDiscounts] = useState(initialPRADefaultValues);
  const [optionsToSelect, setOptionsToSelect] = useState(
    initialPRADefaultValues
  );

  const [goToInstallments, setGoToInstallments] = useState<
    ((paymentMethod: string) => void) | null
  >(null);

  const [isItau, setIsItau] = useState(false);

  const overdueUpcomingDescription = isItau
    ? 'Renegocie todo o contrato: tanto parcelas vencidas, quanto futuras.'
    : 'O bolso apertou? Renegocie tudo e fique livre de preocupações';

  const titleOverdueUpcoming = isItau
    ? 'Quitação total'
    : 'Parcelas vencidas e futuras';

  const getOverdueOnlyInstallments = () => {
    const overdueUpcoming = currentDebt?.opcaoPagamento.filter(
      ({ propriedades }) =>
        propriedades.find((prop) => prop.chave.includes('PRA_UPCOMING'))
    );
    const overdueUpcomingInstallments = overdueUpcoming?.map(
      ({ propriedades }) => {
        const installments = propriedades.find(
          (prop) => prop.chave.includes('PRA_UPCOMING') && prop.valorString
        );
        return installments?.valorString;
      }
    );

    const overdueOnly = currentDebt?.opcaoPagamento.filter(({ propriedades }) =>
      propriedades.find(
        (prop) =>
          prop.chave === constants.debts.PROPERTIES.ITAU_NEGOTIATION_TYPE &&
          prop.valorString === 'OVERDUE'
      )
    );

    const overdueOnlyInstallments = overdueOnly?.map(({ propriedades }) => {
      const installments = propriedades.find(
        (prop) =>
          prop.chave.includes('PRA_OVERDUE_CONTRACT') && prop.valorString
      );
      return installments?.valorString;
    });

    return {
      overdueOnlyRange: overdueOnlyInstallments?.[0],
      overdueUpcomingRange: overdueUpcomingInstallments?.[0],
    };
  };

  const filterInstallments = (selectedOption: string) => {
    const propKey = isItau
      ? constants.debts.PROPERTIES.ITAU_NEGOTIATION_TYPE
      : constants?.riachuelo?.keyRiachueloNegotiationType;

    let paymentOptions;

    if (!selectedOption) {
      paymentOptions = currentDebt?.opcaoPagamento?.filter(
        ({ propriedades }) => {
          const optType = propriedades?.find((prop) => prop.chave === propKey);
          return !optType;
        }
      );
    } else {
      paymentOptions = currentDebt?.opcaoPagamento?.filter(
        ({ propriedades }) => {
          const optType = propriedades?.find((prop) => prop?.chave === propKey);

          return optType && optType?.valorString === selectedOption;
        }
      );
    }
    return { ...currentDebt, opcaoPagamento: paymentOptions };
  };

  const addDebtToLocalStorage = (selectedOption: string) => {
    const filteredDebt = filterInstallments(selectedOption);

    const handleTypeSelected = () => {
      if (isItau && selectedOption === 'OVERDUE') return 'overdued_only';
      if (isItau && selectedOption === '') return 'overdued_and_upcoming';

      return selectedOption;
    };

    const installmentsRange = getOverdueOnlyInstallments();

    const encryptedDebt = legacyBase64Encrypt(
      JSON.stringify({
        ...filteredDebt,
        expTime: new Date(),
        typeSelected: handleTypeSelected(),
        ...(installmentsRange && { installmentsRange: installmentsRange }),
      })
    );

    const encryptedPaymentOption = legacyBase64Encrypt(
      JSON.stringify(filteredDebt?.opcaoPagamento?.[0])
    );

    localStorage.setItem(constants.debts.DEBT, encryptedDebt);

    localStorage.setItem(
      constants.debts.PAYMENT_OPTION,
      encryptedPaymentOption
    );
  };

  const handleClose = () => {
    setIsOpen(false);
    setCurrentDebt(null);
    setGoToInstallments(null);
  };

  const handleClickOption = (option: string) => {
    handleClose();

    setTimeout(() => {
      addDebtToLocalStorage(option);
      if (currentDebt && goToInstallments) {
        const [paymentOption] = currentDebt.opcaoPagamento;
        goToInstallments(paymentOption.paymentMethodName);
      }
    }, 100);
  };

  useImperativeHandle(ref, () => ({
    open({ debt, goToInstallments }) {
      setCurrentDebt(debt);
      setIsOpen(true);
      setGoToInstallments(() => goToInstallments);

      track.userTracking.elementViewed({
        name: `Modal Parcelas vencidas ${debt?.partner?.identifier}`,
      });

      setIsItau(debt.partner.identifier.includes('itau'));

      handleItauExpiredInstallments(debt);
      handleRiachueloExpiredInstallments(debt);
    },
  }));

  const handleItauExpiredInstallments = (debt: SimulatedDebt) => {
    const { hasPRAItau, optionsOverdueOnlyItau, optionsOverdueUpcomingItau } =
      itau.checkPRAItau(debt);
    if (!hasPRAItau || !optionsOverdueOnlyItau) return;

    const currentValueOrverdueOnly = currencyToLocale(
      optionsOverdueOnlyItau?.[0]?.valorAcordo
    );
    const currentValueOverdueUpcoming = currencyToLocale(
      optionsOverdueUpcomingItau?.[0]?.valorAcordo || 0
    );

    setOriginalDebtValues({
      overdueOnly: currencyToLocale(
        optionsOverdueOnlyItau?.[0]?.valorDividaOriginal
      ),
      overdueUpcoming: currencyToLocale(
        optionsOverdueUpcomingItau?.[0]?.valorDividaOriginal || 0
      ),
    });

    let discounts = { overdueOnly: '', overdueUpcoming: '' };

    if (optionsOverdueUpcomingItau?.[0]?.percentualDesconto) {
      const discountOverdueUpcoming = formatPercentage(
        optionsOverdueUpcomingItau?.[0]?.percentualDesconto * 100
      );
      discounts = { overdueUpcoming: discountOverdueUpcoming, overdueOnly: '' };
    }

    if (optionsOverdueOnlyItau?.[0]?.percentualDesconto) {
      const discountValueOverdueOnly = formatPercentage(
        optionsOverdueOnlyItau?.[0]?.percentualDesconto * 100
      );
      discounts = { ...discounts, overdueOnly: discountValueOverdueOnly };
    }
    setDiscounts(discounts);

    setHasOverduedOnly(true);
    setValueOrverdueOnly(currentValueOrverdueOnly);

    setHasOverduedAndUpcoming(true);
    setValueOverdueUpcoming(currentValueOverdueUpcoming);
    setOptionsToSelect({ overdueOnly: 'OVERDUE', overdueUpcoming: '' });
  };

  const handleRiachueloExpiredInstallments = (debt: SimulatedDebt) => {
    if (!debt.partner.identifier.includes('riachuelo')) return;

    const {
      hasOverduedAndUpcoming: overdueUpcoming,
      hasOverduedOnly: overdueOnly,
      optionsOrverdueOnly,
      optionsOverdueUpcoming,
    } = checkExpiredInstallments(debt);
    const currentValueOrverdueOnly = currencyToLocale(
      optionsOrverdueOnly[0]?.valorAcordo
    );
    const currentValueOverdueUpcoming = currencyToLocale(
      optionsOverdueUpcoming[0]?.valorAcordo
    );

    setValueOrverdueOnly(currentValueOrverdueOnly);
    setValueOverdueUpcoming(currentValueOverdueUpcoming);
    setHasOverduedOnly(overdueOnly);
    setHasOverduedAndUpcoming(overdueUpcoming);

    setOptionsToSelect({
      overdueOnly: 'overdued_only',
      overdueUpcoming: 'overdued_and_upcoming',
    });
  };

  return {
    isOpen,
    hasOverduedOnly,
    hasOverduedAndUpcoming,
    titleOverdueUpcoming,
    valueOrverdueOnly,
    valueOverdueUpcoming,
    handleClickOption,
    handleClose,
    optionsToSelect,
    overdueUpcomingDescription,
    praDesc: getOverdueOnlyInstallments(),
    originalDebtValues,
    discounts,
  };
};

export default useExpiredInstallments;
