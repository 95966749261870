import { SimulatedDebt } from '@common/apis/simulated-debt/types';
import { currencyToLocale } from '@libs/utils/helpers/currencyToLocale';
import constants from '@common/constants';
import { SortingDebtResult } from '@common/apis/sorting-debt/types';

const { ITAU_NEGOTIATION_TYPE, ITAU_NEGOTIATION_TYPE_VALUE } =
  constants.debts.PROPERTIES;
export function getLisDebtDetails(debt: SimulatedDebt) {
  type LisKeys = {
    desc: string;
    promiseLimit?: number;
    previousLimit?: number;
    paymentLimit?: number;
  };

  const initialAcc: LisKeys = {
    desc: '',
    promiseLimit: undefined,
    previousLimit: undefined,
    paymentLimit: undefined,
  };

  const lisDetails = debt?.propriedades?.reduce((acc, prop) => {
    const lisKeysMap: Record<string, keyof LisKeys> = {
      DESCRIPTION_LIMIT: 'desc',
      PROMISE_LIMIT: 'promiseLimit',
      PREVIUS_LIMIT: 'previousLimit',
      PAYMENT_LIMIT: 'paymentLimit',
    };

    const key = lisKeysMap[prop.chave];

    if (key !== 'desc') {
      acc[key] = Number(prop.valorString);
    } else {
      acc[key] = prop.valorString;
    }

    return acc;
  }, initialAcc);

  const { desc, promiseLimit, previousLimit, paymentLimit } = lisDetails || {};

  const lisProductDetailsList = [
    {
      ...(previousLimit !== undefined && {
        title: 'Limite atual',
        value: currencyToLocale(previousLimit),
      }),
    },
    {
      ...(promiseLimit !== undefined && {
        title: 'Limite na contratação',
        value: currencyToLocale(promiseLimit),
      }),
    },
    {
      ...(paymentLimit !== undefined && {
        title: 'Limite após pag. da 1ª parcela',
        value: currencyToLocale(paymentLimit),
      }),
    },
    {
      ...(desc && {
        title: 'Nova situação do limite',
        value: desc,
      }),
    },
  ];
  return lisProductDetailsList;
}

export function checkPRAItau(debt?: SimulatedDebt) {
  const isItau = debt?.partner?.identifier.includes('itau');
  if (!isItau) return { hasPRAItau: false };

  const PRAItau = debt?.opcaoPagamento?.some(({ propriedades }) => {
    const optType = propriedades?.find(
      ({ chave }) => chave === ITAU_NEGOTIATION_TYPE
    );

    return optType && optType?.valorString === ITAU_NEGOTIATION_TYPE_VALUE;
  });

  const PRAItauOptions = debt?.opcaoPagamento?.filter(({ propriedades }) => {
    const optType = propriedades?.find(
      ({ chave }) => chave === constants.debts.PROPERTIES.ITAU_NEGOTIATION_TYPE
    );
    return optType && optType?.valorString === ITAU_NEGOTIATION_TYPE_VALUE;
  });

  const PRAItauUpcomingOptions = debt?.opcaoPagamento?.filter(
    ({ propriedades }) => {
      const optType = propriedades?.find(
        ({ chave, valorString }) =>
          chave === constants.debts.PROPERTIES.ITAU_NEGOTIATION_TYPE &&
          valorString === 'OVERDUE'
      );
      return !optType;
    }
  );

  return {
    hasPRAItau: PRAItau,
    optionsOverdueOnlyItau: PRAItauOptions,
    optionsOverdueUpcomingItau: PRAItauUpcomingOptions,
  };
}

export function getPRAPaymentOption(debt: SortingDebtResult) {
  const withPraItau = debt?.paymentOptions?.find(
    ({ paymentOptionProperties }) => {
      const optType = paymentOptionProperties?.find(
        ({ key, stringValue }) =>
          key === ITAU_NEGOTIATION_TYPE &&
          stringValue === ITAU_NEGOTIATION_TYPE_VALUE
      );

      return optType;
    }
  );

  return withPraItau;
}

export function hasPRAType(debt: SimulatedDebt) {
  return (
    debt?.typeSelected?.includes('overdued_and_upcoming') ||
    debt?.typeSelected?.includes('overdued_only')
  );
}
