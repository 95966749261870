import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserStore } from '@common/store/useUserStore';
import { useDogxContext } from '@libs/dogx';

import { SHDecisionGeneralInfo, SHOffer } from '@libs/sortinghat';

import { offerCategory } from '@common/constants/offerCategory';
import { pageNames } from '@common/routes/pageNames';
import useApiFirebolt from '@common/apis/firebolt/useApiFirebolt';
import useTrackOffer from '../useTrackOffer';
import { getSessionID } from 'redline-client-sdk';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { isIOS } from '@libs/utils/helpers/mobileOS';
import { isSafari } from '@libs/utils/helpers/isSafari';
import { getSiteIdParam } from '@libs/utils/helpers/getSiteIdParam';
import { getDeliveryIdParam } from '@libs/utils/helpers/getDeliveryIdParam';
import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { getGAAvailableCookies } from '@libs/utils/helpers/getGoogleAnalyticsCookies';
import {
  addAutofillParam,
  FireboltUserInfo,
} from '@libs/utils/helpers/addAutofillParam';

import { useSortingHatLib } from '../useSortingHatLib';

type GetOffersType = {
  callSHDecide?: boolean;
};

const useGetOffers = ({ callSHDecide }: GetOffersType = {}) => {
  const navigate = useNavigate();
  const { trackProductViewed, trackProposalStarted, trackElementClicked } =
    useTrackOffer();

  const {
    decisionsList,
    decideDone: decideFinish,
    shLoading,
    shDecision,
  } = useSortingHatLib();

  useEffect(() => {
    if (callSHDecide) {
      shDecision();
    }
  }, []);

  const isDev = import.meta.env.VITE_ENV === 'dev';

  const { sendSoftProposal } = useApiFirebolt();
  const { trackProductClicked, trackRedirectRequested } = useTrackOffer();
  const { user, myDebt } = useUserStore((state) => state);
  const [userNegative, setUserNegative] = useState<boolean>(false);
  const [featuredOffers, setFeaturedOffers] = useState<SHOffer[]>([]);
  const [preApprovedOffers, setPreApprovedOffers] = useState<SHOffer[]>([]);
  const [filteredOffers, setFilteredOffers] = useState<SHOffer[]>([]);
  const [offers, setOffers] = useState<SHOffer[]>([]);
  const [openDigioModal, setOpenDigioModal] = useState(false);

  const location = useLocation();

  const initialCategory = location?.state?.key
    ? location.state.key
    : offerCategory.ALL;

  const noOffer = !shLoading && decideFinish && decisionsList.length <= 0;

  //#region TEST AB - New BB-Ourocard Flow
  const { getExperimentVariant } = useDogxContext();
  const hasNewBBFlow = getExperimentVariant('dx-new-bb-flow') === 'has-new-bb-flow';
  //#endregion

  const setStateOffers = (offers: SHOffer[]) => {
    const filteredFeaturedOffers = filterByType(offers, 'featured');
    const filteredPreAapprovedOffers = filterByPath(filteredFeaturedOffers);
    const filteredFeaturedByPath = filterByPath(filteredFeaturedOffers);

    setOffers(offers);
    setFeaturedOffers(filteredFeaturedByPath);
    setPreApprovedOffers(filteredPreAapprovedOffers);
  };

  const filterByPath = (offers: SHOffer[]) => {
    const filteredResult = [
      'ac-destaque-dashboard',
      'ac-destaque-ofertas',
      'ac-monitoramento-financeiro-destaque-ofertas',
      'ac-ofertas-pre-aprovadas-lista-ofertas',
    ].reduce((acc: any, curr: string) => {
      const filteredSlug = offers.filter(({ generalInfo }) => {
        const safeFeatured = Array.isArray(generalInfo['featured'])
          ? generalInfo['featured']
          : [];
        return safeFeatured?.includes(curr);
      });

      return acc
        ? {
            ...acc,
            [curr]: filteredSlug,
          }
        : acc;
    }, {});

    const slugsByPath: Record<string, string> = {
      '/minhas-dividas': 'ac-destaque-dashboard',
      [`/${myDebt?.partner?.identifier}/acordo-fechado`]:
        'ac-destaque-dashboard',
      '/ofertas': 'ac-destaque-ofertas',
      '/ofertas/ofertas-pre-aprovadas':
        'ac-ofertas-pre-aprovadas-lista-ofertas',
      '/monitoramento/financeiro':
        'ac-monitoramento-financeiro-destaque-ofertas',
    };

    return filteredResult[slugsByPath[location.pathname]];
  };

  const filterByType = (
    offers: SHOffer[],
    type: keyof SHDecisionGeneralInfo
  ) => {
    return offers.filter(({ generalInfo }) => generalInfo[type]);
  };

  const filterByCategory = (categoryToFilter: string) => {
    if (categoryToFilter === offerCategory.ALL) {
      setFilteredOffers(offers);
      return offers;
    } else {
      const filtered = offers.filter(
        ({ content }) => content?.category === categoryToFilter
      );
      setFilteredOffers(filtered);
      return filtered;
    }
  };

  const numberOfOffers = (exception?: keyof SHDecisionGeneralInfo) => {
    if (exception) {
      const offersWithExceptions = offers?.filter(
        ({ generalInfo }) => !generalInfo?.[exception]
      );

      return offersWithExceptions.length;
    } else {
      return offers.length;
    }
  };

  function redirectToOffers() {
    trackElementClicked({
      location: 'ac-minhas-dividas-destaque-ofertas',
      elementType: 'button',
      name: pageNames.offers.path,
      text: 'Ver ofertas',
    });
    navigate(pageNames.offers.path, { state: { key: offerCategory.ALL } });
  }

  function onCloseDigioModal() {
    setOpenDigioModal(false);
  }

  const onClickOffer = async (
    offer: SHOffer,
    location: string,
    index: number
  ) => {
    const { generalInfo, content } = offer;

    const isDifferentFlow = await handleDifferentFlows({
      ...offer,
      location,
      index,
    });
    if (isDifferentFlow) return null;

    const proposalSoft = await sendProposal(
      generalInfo.partner,
      generalInfo.slug
    );
    const fireboltId = proposalSoft?.firebolt_id || '';
    trackProductClicked(offer, location, index, fireboltId);

    let url =
      proposalSoft?.webhookResult.redirectUrl ||
      (await getUrl(content?.links, fireboltId, generalInfo?.preApproved));

    if (url) {
      await sendEventProposalStarted(offer.content, url, location, fireboltId);
      if (generalInfo.slug === 'cards-neon') {
        return url && redirectToOfferNeon(offer, location, url, proposalSoft);
      }

      if (content?.full_funnel) {
        const userInfo: FireboltUserInfo = {
          cpf: user?.documento,
          fullName: user?.nome,
          email: user?.email,
          phone: user?.celular,
          birthdate: user?.dataNascimento,
        };

        url = addAutofillParam({
          productSlug: generalInfo.slug,
          url,
          user: userInfo,
        });

        if (isDev) {
          url = url.replace('https://www.', 'https://dev.');
        }
      }

      redirect(url);
      trackRedirectRequested(offer, location, url, fireboltId);
    }
  };

  const handleDifferentFlows = async (
    offer: SHOffer & { location: string; index: number }
  ) => {
    const { generalInfo, content, location, index } = offer;
    const url = await getUrl(content?.links, '', generalInfo?.preApproved);

    //#region TEST AB - New BB-Ourocard Flow
    const redirectToBB = (bbUrl: string) => {
      trackProductClicked(offer, location, index, '');
      trackRedirectRequested(offer, location, bbUrl, '');
      redirect(bbUrl);
    };
    //#endregion

    switch (generalInfo?.slug) {
      case 'cards-digio':
        setOpenDigioModal(true);
        return true;
      case 'cards-bancodobrasil-ourocardfacil':
        //#region TEST AB - New BB-Ourocard Flow

        const userInfo: FireboltUserInfo = {
          cpf: user?.documento,
          fullName: user?.nome,
          email: user?.email,
          phone: user?.celular,
          birthdate: user?.dataNascimento,
        };

        if (url) {
          const bbTestUrl = isDev
            ? 'dev.acordocerto.com.br/produtos'
            : 'acordocerto.com.br/produtos';
          const newBBTestUrl = url.replace(
            'produto.acordocerto.com.br/produto',
            bbTestUrl
          );
          const finalBbTestUrl = addAutofillParam({
            productSlug: generalInfo.slug,
            url: newBBTestUrl,
            user: userInfo,
          });
          hasNewBBFlow ? redirectToBB(finalBbTestUrl) : redirectToBB(url);
        }
        return true;
      //#endregion
    }
  };

  const redirectToOfferNeon = async (
    offer: SHOffer,
    location: string,
    url: string,
    fireboltInfo: any
  ) => {
    const redirectUrls = fireboltInfo?.webhookResult?.processedData as Partial<{
      storyblok_redirect_urls: { param: string; redirect: string }[];
    }>;
    const acOrganicUrl = redirectUrls.storyblok_redirect_urls?.find(
      (url) => url.param === 'ac_organic'
    );

    if (acOrganicUrl) {
      window.open(acOrganicUrl.redirect, '_blank');
      trackRedirectRequested(
        offer,
        location,
        acOrganicUrl.redirect,
        fireboltInfo?.firebolt_id
      );
    } else {
      redirect(url);
      trackRedirectRequested(offer, location, url, fireboltInfo?.firebolt_id);
    }
  };

  async function sendProposal(partnerSlug: string, productSlug: string) {
    const { customerIdHash } = user;
    const payload = {
      item: {
        cpf: user?.documento,
        full_name: user?.nome,
        email: user?.email,
        main_phone: user?.celular,
        date_of_birth: user?.dataNascimento,
        choosen_card: partnerSlug,
        issuer: partnerSlug,
        product_slug: productSlug,
      },
      metadata: {
        start_source_url: getCookie('start_source_url'),
        anonymousId: customerIdHash,
        sessionId: getSessionID(),
        fbCookies: {
          fcbId: getFbAvailableCookies().fbc,
          fbpId: getFbAvailableCookies().fbp,
        },
        gaCookies: {
          gaId: getGAAvailableCookies().ga,
        },
      },
    };

    try {
      const response = await sendSoftProposal.send(partnerSlug, payload);
      return response;
    } catch (error) {
      console.error('falha na requisição firebolt soft', error);
    }
  }

  const getUrl = (links: any, fireboltId: string, isPreApproved?: boolean) => {
    const safeLinks = links || [];
    const linksAC = safeLinks.find(
      (link: any) => link.application === 'ac-links'
    );

    if (!linksAC) return;

    const preApprovedLink = userNegative
      ? linksAC?.link_pre_approved_negative
      : linksAC?.link_pre_approved;
    const defaultLink = userNegative
      ? linksAC?.link_with_debts
      : linksAC?.link_without_debts;
    const link = isPreApproved ? preApprovedLink : defaultLink;
    const urlWithParams = fillUrlParameters(link?.url, fireboltId);

    return urlWithParams;
  };

  const fillUrlParameters = async (url: string, fireboltId: string) => {
    const { customerIdHash } = user;
    const startSourceUrl = getCookie('start_source_url') || '';
    const deliveryId = getDeliveryIdParam({ startSourceUrl });
    const siteId = await getSiteIdParam({ marketplace: 'ac', startSourceUrl });

    url = url
      .replace(/\(firebolt_id\)/g, fireboltId)
      .replace('(af_siteid)', siteId || '')
      .replace('(delivery_id)', deliveryId);
    if (url.includes('noverde')) {
      url = url.replace('(utm_term)', customerIdHash);
    }

    const urlObj = new URL(url);
    const searchParams = urlObj.searchParams;

    const filledParams: Record<string, string> = {
      anonymous_id: customerIdHash + Date.now(),
      session_id: customerIdHash + Date.now(),
      aff_unique2: customerIdHash,
      clickID: customerIdHash + 'T' + Date.now(),
      firebolt_id: fireboltId,
    };

    Object.keys(filledParams).forEach((chave) => {
      if (searchParams.has(chave)) {
        searchParams.set(chave, filledParams[chave]);
      }
    });

    return urlObj.toString();
  };

  const sendEventProductViewed = (
    offer: SHOffer = {} as SHOffer,
    index: number,
    location: string
  ) => {
    if (!offer || !offer?.content || !offer?.generalInfo) return;

    const { content, generalInfo } = offer;
    const { slug, title, category, partner, variant } = content;

    const payload = {
      slug,
      category,
      variant,
      name: title,
      brand: partner,
      price: generalInfo?.limit || 0,
      position: index,
      location: location,
    };

    trackProductViewed(payload);
  };

  const sendEventProposalStarted = async (
    contentOffer: SHOffer['content'],
    redirectUrl: string,
    location: string,
    fireboltId: string
  ) => {
    if (!contentOffer) return;
    const { slug, category } = contentOffer;

    if (contentOffer?.full_funnel) return;

    const payload = {
      initialSlug: slug,
      finalSlug: slug,
      category,
      redirectUrl,
      location,
      fireboltId,
    };
    await trackProposalStarted(payload);
  };

  const redirect = (url: string) => {
    if (isIOS() || isSafari()) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  };

  useEffect(() => {
    filterByCategory(initialCategory);
  }, [offers]);

  useEffect(() => {
    if (decisionsList?.length > 0) {
      setStateOffers(decisionsList);
    }
  }, [decisionsList]);

  return {
    offers,
    featuredOffers,
    preApprovedOffers,
    userNegative,
    filterByCategory,
    filteredOffers,
    initialCategory,
    numberOfOffers,
    onClickOffer,
    redirectToOffers,
    onCloseDigioModal,
    openDigioModal,
    sendEventProductViewed,
    decideFinish,
    shLoading,
    noOffer,
  };
};
export default useGetOffers;
