import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Text from '@libs/atlas/components/Text';
import { IconChevronRight } from '@libs/atlas/components/icons/default';
import Conditional from '@libs/atlas/components/misc/Conditional';
import { CardOfferDetailsProps } from './types';
import HeaderMainTitle from './Header/HeaderMainTitle';
import { formatHighlight } from '@hub/helpers/formatHighlight';

const CardOfferDetails = ({
  details,
  toggleDetails = false,
  name,
  subtitle = '',
  changeCarousel,
  value,
  limit,
  slug,
  annuity,
  partner,
  onClick,
}: CardOfferDetailsProps) => {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => setCollapsed(false), [changeCarousel]);
  const titleText = value && name ? name.replace('(value)', value) : name || '';

  const collapseClass = classNames('card-offer-container__more', {
    'card-offer-container__more--collapsed': collapsed,
  });

  return (
    <div>
      <div
        className={classNames('card-offer-container__collapsed-details', {
          'card-offer-container__collapsed-details--collapsed':
            !toggleDetails || collapsed,
          'card-offer-container__collapsed-details--line':
            toggleDetails && collapsed,
        })}
      >
        <HeaderMainTitle
          title={titleText}
          subtitle={subtitle}
          limit={limit}
          slug={slug}
        />
        <div className="card-offer-container__line">
          {details?.map(({ _uid, icon, description, slug }) => {
            return (
              <div
                className={classNames('card-offer-container__collapsed-item', {
                  'card-offer-container__collapsed-item--carousel':
                    toggleDetails,
                })}
                key={_uid}
              >
                <img
                  src={icon?.filename}
                  alt={icon?.alt || 'icone'}
                  width={24}
                  height={24}
                />{' '}
                {formatHighlight({
                  description,
                  descriptionSlug: slug,
                  partner,
                  annuity,
                })}
              </div>
            );
          })}
        </div>
      </div>
      <Conditional
        condition={toggleDetails && Boolean(details && details?.length > 0)}
        renderIf={
          <span
            onClick={() => {
              setCollapsed((prev) => !prev);
              onClick && onClick();
            }}
            className={collapseClass}
          >
            <Text as="p" color="principal" weight="semibold">
              {collapsed ? 'Esconder detalhes' : 'Ver detalhes'}
            </Text>
            <IconChevronRight />
          </span>
        }
      />
    </div>
  );
};

export default CardOfferDetails;
