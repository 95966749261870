// import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import Base64 from 'crypto-js/enc-base64';
import UTF8 from 'crypto-js/enc-utf8';
import { isLocal } from './isLocal';

type ValueProps = string | object;

interface AddProps {
  key: string;
  value: ValueProps;
  encrypt?: boolean;
  expires?: Date;
}

const domain = import.meta.env.VITE_DOMAIN
const customBuild = !!import.meta.env.VITE_CUSTOM_BUILD

export function getAllCookies(): { [key: string]: string } {
  const cookies: { [key: string]: string } = {};

  document.cookie.split(';').forEach((cookie) => {
    const [name, value] = cookie.split('=');
    const cookieName = name?.trim();
    const cookieValue = decodeURIComponent(value?.trim());
    cookies[cookieName] = cookieValue;
  });

  return cookies;
}

export const checkCookie = (key: string): boolean => {
  return key in getAllCookies();
};

export const getCookie = (
  key: string,
  decrypt?: boolean
): string | undefined => {
  const valueEncoded = Cookies.get(key);

  return decrypt && valueEncoded
    ? Base64.parse(valueEncoded).toString()
    : valueEncoded;
};

export const addCookie = ({ key, value, encrypt, expires }: AddProps): void => {
  const currentValue =
    typeof value === 'object' ? JSON.stringify(value) : value;
  const encodedWord = UTF8.parse(currentValue);
  const valueEncoded = encrypt ? Base64.stringify(encodedWord) : currentValue;

  Cookies.set(key, valueEncoded, {
    expires,
    domain: isLocal() ? 'localhost' : domain,
    sameSite: 'None',
    secure: true
  });
};

export const removeCookie = (key: string): void => {
  const cookieDomain = isLocal()
  ? 'localhost'
  : customBuild
  ? "consumidorpositivo.com.br"
  : domain;

  Cookies.remove(key, {
    domain: cookieDomain,
  });
};

export const removeWithKeyIncludes = (partialKey: string): void => {
  Object.keys(getAllCookies()).forEach((key) => {
    if (key.includes(partialKey)) {
      removeCookie(key);
    }
  });
};

export const getCookiesStartsWith = (partialKey: string): Record<string, string | undefined> => {
  const cookies: { [key: string]: string | undefined } = {};

  Object.keys(getAllCookies()).forEach((cookie) => {
    const value = getCookie(cookie)
    if (cookie.startsWith(partialKey)) {
      cookies[cookie] = value;
    }
  });

  return cookies
};
