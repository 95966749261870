import { useEffect, useState } from 'react';
import useApiPix from '@common/apis/pix/useApiPix';

import { partnersWithPixExternal } from '../usePixExperiment';

import {
  IconAlertTriangle,
  IconMoney,
} from '@libs/atlas/components/icons/default';
import { useRedline } from '@libs/redline';
import {
  statusLabelsProps,
  usePixProps,
  statusDictionaryProps,
  pixPaymentStatusTypes,
  handleQRCodeImageProps,
} from './types';
import constants from '@common/constants';
import {
  legacyBase64Decrypt,
  legacyBase64Encrypt,
} from '@libs/utils/helpers/base64';
import useGTM from '../useGTM';
import useClosedAgreementExperiment from '@common/abtest/experiments/useClosedAgreementExperiment';

const usePix = ({
  installment,
  agreement,
  isMyAgreementsPage = false,
  billetInternalPix,
  debt,
  paymentOption,
  closedAgreement,
  refCopiedWarning,
}: usePixProps) => {
  const { track: trackRedline } = useRedline();
  const GTM = useGTM();

  const {
    apiGetPixInternal,
    apiGeneratePixInternal,
    apiCheckPixPaymentStatus,
    apiGeneratePixExternal,
  } = useApiPix(debt?.partner?.msUrl);

  const [pixData, setPixData] = useState<any>(
    closedAgreement?.pixData ? closedAgreement?.pixData : undefined
  );
  const [paymentStatus, setPaymentStatus] = useState<pixPaymentStatusTypes>(
    () => {
      const statusInLocalEncrypt = localStorage.getItem(
        constants.debts.PIX_STATUS
      );
      const statusDecrypt =
        statusInLocalEncrypt && !isMyAgreementsPage
          ? legacyBase64Decrypt(statusInLocalEncrypt)
          : '';

      return statusDecrypt ? statusDecrypt : 'pending';
    }
  );

  const [loadingPix, setLoadingPix] = useState<boolean>(true);

  const idPartner =
    (agreement?.partner?.identificador || debt?.partner?.partnerIdentifier) ??
    '';
  const [isPixExternal] = useState(() =>
    partnersWithPixExternal?.includes(idPartner)
  );
  const [singlePaymentMethod] = useState(() =>
    constants.debts.PARTNERS_WITH_SINGLE_PAY_METHOD?.includes(idPartner)
  );
  const [currentPaymentMethod] = useState(
    paymentOption?.paymentMethodName || agreement?.paymentMethod
  );

  //#region TODO: improve this stretch >>>>
  const [pixCustomId, setPixCustomId] = useState<string>(
    closedAgreement?.pixData?.data?.custom_id
      ? closedAgreement?.pixData?.data?.custom_id
      : ''
  );
  const [pixCode, setPixCode] = useState<string>(
    closedAgreement?.pixData?.data?.pix_code
      ? closedAgreement?.pixData?.data?.pix_code
      : ''
  );
  const [qrCodeUrl, setQrCodeUrl] = useState<string>(() => {
    const { qrcode_base64, qrcode_link } = closedAgreement?.pixData?.data || {};
    return !!qrcode_base64 || !!qrcode_link
      ? handleQRCodeImage({ qrcode_base64, qrcode_link })
      : '';
  });
  //#endregion <<<<<
  const { resultIsNewPaymentPage } = useClosedAgreementExperiment();
  const copyPixButtonText = resultIsNewPaymentPage
    ? 'Copiar código do Pix'
    : 'Copiar Pix copia e cola';
  const [buttonText, setButtonText] = useState<string>(copyPixButtonText);

  const [isPixLoading, setIsPixLoading] = useState<boolean>(false);
  const [isPixSuccess, setIsPixSuccess] = useState<boolean>(false);
  const [isLoadingTryAgain, setIsLoadingTryAgain] = useState<boolean>(false);

  const [isCodeCopied, setIsCodeCopied] = useState<boolean>(false);

  const statusDictionaryMyAgreements: statusDictionaryProps = {
    error: ['PIX_REFUNDED'],
    pending: ['PIX_PENDING_PAYMENT'],
    pixPaid: [
      'PIX_PAID',
      'BILLET_PAID',
      'BILLET_PAYMENT_ERROR',
      'BILLET_REFUNDED',
      'PIX_ERROR_REFUNDED',
    ],
  };

  const statusDictionaryClosedAgreement: statusDictionaryProps = {
    error: [
      'BILLET_PAYMENT_ERROR',
      'PIX_REFUNDED',
      'PIX_ERROR_REFUNDED',
      'BILLET_REFUNDED',
    ],
    pending: ['PIX_PENDING_PAYMENT'],
    pixPaid: ['PIX_PAID', 'BILLET_PAID'],
  };

  const statusLabels: statusLabelsProps = {
    pending: {
      text: 'Aguardando pagamento...',
      type: 'warning',
      icon: <></>,
    },
    pixPaid: {
      text: 'Pagamento em análise...',
      type: 'success',
      icon: !isMyAgreementsPage ? <></> : <IconMoney />,
    },
    inAnalysis: {
      text: 'Pagamento em análise...',
      type: 'success',
      icon: !isMyAgreementsPage ? <></> : <IconMoney />,
    },
    error: {
      text: 'Pagamento reembolsado',
      type: 'error',
      icon: <IconAlertTriangle />,
    },
  };

  function getPixStatus(
    status: string,
    callBack: (status: pixPaymentStatusTypes) => void
  ) {
    const statusDictionary = isMyAgreementsPage
      ? statusDictionaryMyAgreements
      : statusDictionaryClosedAgreement;

    Object.keys(statusDictionary).forEach((key: string) => {
      const validStatus = key as pixPaymentStatusTypes;

      const currentStatus = statusDictionary[validStatus];
      if (currentStatus.includes(status.toUpperCase() || status))
        callBack(validStatus);
    });
  }

  const checkPaymentStatus = async () => {
    apiCheckPixPaymentStatus
      .send({ installmentCode: installment.installmentCode })
      .then((response) => {
        const statusApi = response.data.status || '';

        getPixStatus(statusApi, (status) => {
          localStorage.setItem(
            constants.debts.PIX_STATUS,
            legacyBase64Encrypt(status)
          );

          setPaymentStatus(status);
        });
      })
      .catch((error) => {
        console.error('Error fetching payment status:', error);
      });
  };

  const pollingStatusPaymentPix = () => {
    if (pixCode !== '' && !isPixExternal) {
      const TEN_SECONDS_IN_MS = isMyAgreementsPage ? 10000 : 4000;
      const intervalStatusPix = setInterval(
        checkPaymentStatus,
        TEN_SECONDS_IN_MS
      );

      if (paymentStatus !== 'pending') {
        clearInterval(intervalStatusPix);
      }

      return () => clearInterval(intervalStatusPix);
    }
  };

  const handleCopyPixCode = () => {
    trackRedline.userTracking.elementClicked({
      name: 'Pix: Copiar Pix copia e cola',
      location: window.location.pathname,
      elementType: 'button',
    });

    const trackingData = {
      code: pixCode,
      codeType: 'PIX',
      agreementId: agreement?.id || closedAgreement?.agreement?.id,
      debtId: agreement?.debt?.id || closedAgreement?.debt?.id,
      installmentId: installment.id,
      installment: installment?.installmentNumber || installment?.parcela,
      installmentValue:
        installment?.installmentValue || installment?.valorParcela,
    };

    const msReturnDefaultButton = 2000;

    setIsPixLoading(true);

    navigator.clipboard
      .writeText(pixCode)
      .then(() => {
        trackRedline.agreements.paymentCodeCopied(trackingData);

        setIsCodeCopied(true);

        setIsPixLoading(false);
        setIsPixSuccess(true);

        if (refCopiedWarning?.current) {
          refCopiedWarning.current.open({
            visible: true,
            typeCode: 'Pix',
          });
        }

        setButtonText('Código pix copiado');

        setTimeout(() => {
          setIsPixLoading(false);
          setIsPixSuccess(false);

          setIsCodeCopied(false);
          setButtonText(copyPixButtonText);

          if (refCopiedWarning?.current) {
            refCopiedWarning.current.open({
              visible: false,
            });
          }
        }, msReturnDefaultButton);
      })
      .catch((error) => {
        trackRedline.agreements.paymentCodeCopyErrored({
          ...trackingData,
          errorMessage: error.message,
        });

        console.error('Erro ao copiar o código:', error);
      });
  };

  const generatePixExternal = (isTryAgain: boolean) => {
    if (!isMyAgreementsPage && !closedAgreement?.flowWithPix) return;
    if (!isMyAgreementsPage && closedAgreement?.pixData?.data?.pix_code) return;

    const installmentCode = isMyAgreementsPage
      ? installment?.installmentCode
      : agreement?.acordoParcelas?.[0]?.installmentCode;

    const requestPix = {
      installmentCode: installmentCode,
    };

    if (isTryAgain) setIsLoadingTryAgain(true);

    const msUrl = agreement?.partner?.msUrl || debt?.partner?.msUrl;
    apiGeneratePixExternal
      .send(msUrl, requestPix)
      .then((response) => {
        const safeData = response.data || {};
        const { pix_code, qrcode_base64, qrcode_link, custom_id, hasPix } =
          safeData;
        setPixData(response);

        if (!isMyAgreementsPage) {
          // TODO: util, next chapter  <<<
          const closedAgreementFromLocal =
            localStorage.getItem(constants.debts.CLOSED_AGREEMENT) || '';
          const descryptClosedAgreement = JSON.parse(
            legacyBase64Decrypt(closedAgreementFromLocal)
          );

          const updatedClosedAgreement = {
            ...descryptClosedAgreement,
            pixData: response,
          };
          const encryptClosedAgreement = legacyBase64Encrypt(
            JSON.stringify(updatedClosedAgreement)
          );

          localStorage.setItem(
            constants.debts.CLOSED_AGREEMENT,
            encryptClosedAgreement
          );
        }

        if (pix_code && hasPix) {
          const currDebt = isMyAgreementsPage ? agreement?.debt : debt;
          trackRedline.debts.pixGenerated({
            pixCode: pix_code,
            paymentOption,
            debt: currDebt,
            agreement: agreement,
            isExternalPix: true,
          });

          setPixCode(pix_code);
          setQrCodeUrl(handleQRCodeImage({ qrcode_base64, qrcode_link }));
          setPixCustomId(custom_id);
        }
        if (isTryAgain) setIsLoadingTryAgain(false);
      })
      .catch((error) => {
        console.error('Error generating pix', error);
        // setPaymentStatus("errorRequest") TODO: Add new status for the user to try to generate the pix again?
        if (isTryAgain) setIsLoadingTryAgain(false);
      })
      .finally(() => setLoadingPix(false));
  };

  const generatePixInternal = (isTryAgain: boolean) => {
    if (!isMyAgreementsPage && !closedAgreement?.flowWithPix) return;

    const hasPixCode = closedAgreement?.pixData?.data?.pix_code;
    if (!isMyAgreementsPage && hasPixCode || !isMyAgreementsPage && !billetInternalPix) return;

    const agreementInstallments = agreement?.acordoParcelas?.[0];

    const requestPix = {
      barcode: billetInternalPix,
      installment_id: agreementInstallments.id,
      payment_method: agreement?.paymentMethod,
    };

    if (isTryAgain) setIsLoadingTryAgain(true);

    apiGeneratePixInternal
      .send(requestPix)
      .then((response) => {
        const safeData = response.data || {};
        const { pix_code, qrcode_link, qrcode_base64, custom_id, hasPix } =
          safeData;
        setPixData(response);

        if (!isMyAgreementsPage) {
          // TODO: util, next chapter  <<<
          const closedAgreementFromLocal =
            localStorage.getItem(constants.debts.CLOSED_AGREEMENT) || '';
          const descryptClosedAgreement = JSON.parse(
            legacyBase64Decrypt(closedAgreementFromLocal)
          );

          const updatedClosedAgreement = {
            ...descryptClosedAgreement,
            pixData: response,
          };
          const encryptClosedAgreement = legacyBase64Encrypt(
            JSON.stringify(updatedClosedAgreement)
          );

          localStorage.setItem(
            constants.debts.CLOSED_AGREEMENT,
            encryptClosedAgreement
          );
        }

        if (pix_code && hasPix) {
          const currDebt = isMyAgreementsPage ? agreement?.debt : debt;
          trackRedline.debts.pixGenerated({
            pixCode: pix_code,
            paymentOption,
            debt: currDebt,
            agreement: agreement,
          });

          GTM.trigger.pixGenerated();

          setPixCode(pix_code);
          setQrCodeUrl(handleQRCodeImage({ qrcode_base64, qrcode_link }));
          setPixCustomId(custom_id);
        }

        if (isTryAgain) setIsLoadingTryAgain(false);
      })
      .catch((error) => {
        console.error('[ERROR GENERATING PIX]', error);
        if (isTryAgain) setIsLoadingTryAgain(false);
        setQrCodeUrl('');

        trackRedline.debts.pixGenerationErrored({
          errorDetails: error?.fields?.[0]?.field_name || 'Unknown details',
          errorMessage: error?.message || 'Unknown message',
          errorType: error?.error_slug || 'Unknown type',
          paymentOption,
          debt,
          agreement: agreement,
        });
      })
      .finally(() => setLoadingPix(false));
  };

  const getPixCodeInternal = () => {
    if (isPixExternal) return;

    const { installmentCode = '' } = installment || {};

    apiGetPixInternal
      .send({ installmentCode })
      .then((response) => {
        const {
          pix_code,
          custom_id,
          has_pix,
          status,
          qrcode_link,
          qrcode_base64,
        } = response.data;
        setPixData(response);

        if (!has_pix && !isPixExternal) return;

        setQrCodeUrl(handleQRCodeImage({ qrcode_base64, qrcode_link }));
        setPixCode(pix_code);

        if (!!status)
          getPixStatus(status, (status) => {
            return setPaymentStatus(status);
          });
        if (custom_id) setPixCustomId(custom_id);
      })
      .catch((error) => {
        console.error('[GET PIX CODE]:', error);
      })
      .finally(() => setLoadingPix(false));
  };

  const handleGeneratePixCode = (isTryAgain = false) => {
    if (installment?.properties) {
      const pixAgreementProperty = installment.properties.find(
        (property: { key: string; stringValue: string }) =>
          property.key === 'AGREEMENT_EXTERNAL_PIX_CODE'
      );

      if (pixAgreementProperty?.stringValue) {
        setPixCode(pixAgreementProperty.stringValue);
      }
    }

    if (agreement && agreement?.situation !== 'ACTIVE') return; // create a global enum for the statuses and situations of agreements

    const currentPaymentIsPix = currentPaymentMethod === 'PIX';

    //#region itau rule for pix
    const isItau = agreement?.partner?.identificador === 'itau';
    const isFirstInstallment = installment?.installmentNumber === 1;

    if (isItau && !isFirstInstallment && currentPaymentIsPix) return;
    //#endregion itau rule for pix

    const notReqPix = singlePaymentMethod && !currentPaymentIsPix;
    if (notReqPix || (pixData && !!qrCodeUrl)) return;

    if (isPixExternal) {
      generatePixExternal(isTryAgain);
    } else if (!isMyAgreementsPage && !isPixExternal) {
      generatePixInternal(isTryAgain);
    } else {
      getPixCodeInternal();
    }
  };

  function handleQRCodeImage({
    qrcode_base64,
    qrcode_link,
  }: handleQRCodeImageProps) {
    if (!!qrcode_base64) return `data:image/png;base64,${qrcode_base64}`;

    return qrcode_link;
  }

  useEffect(() => handleGeneratePixCode(), []);
  useEffect(() => pollingStatusPaymentPix(), [pixCode, paymentStatus]);

  return {
    pixData,
    paymentStatus,
    setPaymentStatus,

    pixCode,
    qrCodeUrl,
    pixCustomId,
    setPixCustomId,

    isPixExternal,

    handleCopyPixCode,
    handleGeneratePixCode,
    isLoadingTryAgain,
    buttonText,
    statusLabels,

    checkPaymentStatus,
    isPixLoading,
    isPixSuccess,
    isCodeCopied,
    statusDictionaryClosedAgreement,
    singlePaymentMethod,
    loadingPix,
  };
};

export default usePix;
