import { Conditional, IconAlertTriangle, Text, COLOR_WARNING_50 } from "@consumidor-positivo/aurora";

import Drawer from "@libs/atlas/components/Drawer";
import Message from "@libs/atlas/components/Message";

import securitizingPartners from "@common/data/securitizingPartners";
import partnersContact from "@common/data/partnersContact";

import './styles.scss';

type UnrecognizedDebtModalProps = {
  partnerName: string;
  identifier: string;
  debtOrigin: string;
  isDebtNegative: boolean;
  isModalOpen: boolean;
  onCloseModal: () => void;
  dismissUnrecognizedDebtModal: () => void;
};

const UnrecognizedDebtModal = ({
  partnerName,
  identifier,
  debtOrigin,
  isDebtNegative,
  isModalOpen,
  onCloseModal,
  dismissUnrecognizedDebtModal
}: UnrecognizedDebtModalProps) => {
  const shouldShowDebtSource = !!debtOrigin && !!securitizingPartners[identifier];

  const formatPhoneLink = (phone: string) => `tel:${phone.replace(/[^0-9]/g, '')}`;
  const formatSiteLink = (site: string) => site.startsWith('http') ? site : `https://${site}`;

  const renderContactInfo = (
    label: string,
    contact: string | string[] | undefined,
    formatter: (input: string) => string
  ) => {
    if (!contact) return null;

    const formattedContact = Array.isArray(contact)
      ? contact.map((item, index) => (
        <span key={index}>
          <a className="unrecognized-debt-modal__link" href={formatter(item)}>{item}</a>
          {index < contact.length - 1 && " / "}
        </span>
      ))
      : <a className="unrecognized-debt-modal__link" href={formatter(contact)}>{contact}</a>;

    return (
      <>
        <strong>{label}: </strong>
        {formattedContact}
        <br />
      </>
    );
  };

  const { telefone, ouvidoria, sac, whatsapp, site, email, site_movel, site_fixo } = partnersContact[identifier] ?? {};

  return (
    <Drawer
      title="Não reconhece essa dívida?"
      isActive={isModalOpen}
      buttonText="Entendi"
      onClose={onCloseModal}
      onClickButton={dismissUnrecognizedDebtModal}
      className="unrecognized-debt-modal"
    >
      <div className="unrecognized-debt-modal__content">
        <Conditional
          condition={!!shouldShowDebtSource}
          renderIf={
            <Text as="p" variant="body-medium" color="secondary">Essa dívida tem origem na empresa <strong>{debtOrigin}</strong> e está sendo negociada pela empresa <strong>{partnerName}</strong>, responsável pelos dados apresentados aqui. Recomendamos entrar em contato com a empresa negociadora:</Text>
          }
          renderElse={
            <Text as="p" variant="body-medium" color="secondary">Essa dívida é com a empresa <strong>{partnerName}</strong>, responsável pelos dados apresentados aqui. Recomendamos entrar em contato direto com a credora:</Text>
          }
        />

        <Text as="p" variant="body-medium" color="secondary">
          {renderContactInfo('Telefone', telefone, formatPhoneLink)}
          {renderContactInfo('WhatsApp', whatsapp, formatPhoneLink)}
          {renderContactInfo('Ouvidoria', ouvidoria, formatPhoneLink)}
          {renderContactInfo('SAC', sac, formatPhoneLink)}
          {identifier === 'vivo' && (
            <>
              {site_movel && (
                <>
                  <strong>Site Móvel: </strong>
                  <a className="unrecognized-debt-modal__link" href={formatSiteLink(site_movel)} target="_blank">{formatSiteLink(site_movel)}</a><br />
                </>
              )}
              {site_fixo && (
                <>
                  <strong>Site Fixo: </strong>
                  <a className="unrecognized-debt-modal__link" href={formatSiteLink(site_fixo)} target="_blank">{formatSiteLink(site_fixo)}</a><br />
                </>
              )}
            </>
          )}
          {site && (
            <>
              <strong>Site: </strong>
              <a className="unrecognized-debt-modal__link" href={formatSiteLink(site)} target="_blank">{site}</a><br />
            </>
          )}
          {email && (
            <>
              <strong>E-mail: </strong>
              <a className="unrecognized-debt-modal__link" href={`mailto:${email}`}>{email}</a><br />
            </>
          )}
        </Text>

        <Text as="p" variant="body-medium" color="secondary">Ainda tem dúvidas?{' '}
          <a className="unrecognized-debt-modal__link" href="https://www.youtube.com/watch?v=h4enIC3oxh0" target="_blank">Clique aqui para ver nosso vídeo com mais informações.</a>
        </Text>
        <Conditional
          condition={isDebtNegative}
          renderIf={
            <Message type="custom" customClass="unrecognized-debt-modal" icon={<IconAlertTriangle rawColor={COLOR_WARNING_50} />}>
              Mesmo que a dívida esteja prescrita, <strong>ou seja tenha mais de 5 anos</strong>, ainda dá pra negociar se a empresa parceira tiver ela registrada.
            </Message>
          }
        />
      </div>
    </Drawer>
  )
}

export default UnrecognizedDebtModal;