import { SHContent, SHDecision, SHMetadata, SHOffer } from '../types';
import { getLimitMessage } from './getLimitMessage';

export function getOffersFromDecide(decisionList?: SHDecision[]) {
  try {
    if (!decisionList || decisionList?.length < 1) {
      throw new Error('No content found in SH decide');
    }

    const formattedDecisions: SHOffer[] = [];
    for (const decision of decisionList) {
      if (!decision?.metadata?.content?.title) continue;

      const metadata: Partial<SHMetadata> = { ...decision.metadata };
      const content: SHContent = { ...decision.metadata.content };
      delete metadata.content;

      const formattedDecision: SHOffer = {
        generalInfo: {
          annuity: decision?.annuity,
          category: decision?.category,
          id: decision?.id,
          partner: decision?.partner,
          slug: decision?.slug,
          featured: decision?.featured,
          limit: decision?.limit,
          lowLimit: decision?.lowLimit,
          preApproved: decision?.preApproved,
          value: decision?.value,
          modal: decision?.modal,
        },
        metadata,
        content,
      };

      formattedDecisions.push(getLimitMessage( formattedDecision));
    }

    return formattedDecisions;
  } catch (error) {
    console.error('[SH] Error getting offers from decide:', error);
    return [];
  }
}

export function getOffersFromStoryblok(storyblokContent: any) {
  try {
    if (storyblokContent?.stories?.length < 1) {
      throw new Error('No content found in storyblok cache');
    }

    const formattedOffers: SHOffer[] = [];
    for (const story of storyblokContent.stories) {
      const offerContent = story?.content;
      if (!story?.slug || !offerContent?.title) continue;

      const offer: SHOffer = {
        generalInfo: {
          category: offerContent?.category,
          id: offerContent?.id,
          partner: offerContent?.partner,
          slug: offerContent?.slug,
          featured: offerContent?.featured,
          limit: offerContent?.limit,
          limitMessage: offerContent?.limitMessage,
          lowLimit: offerContent?.lowLimit,
          preApproved: offerContent?.preApproved,
          value: offerContent?.value,
          modal: offerContent?.modal,
        },
        metadata: {
          alternates: offerContent?.alternates,
          id: offerContent?.id,
          is_startpage: offerContent?.is_startpage,
          name: offerContent?.name,
          path: offerContent?.path,
          position: offerContent?.position,
          slug: offerContent?.slug,
          uuid: offerContent?.uuid,
        },
        content: {
          _uid: offerContent?._uid,
          name: offerContent?.name,
          slug: offerContent?.slug,
          links: offerContent?.links,
          title: offerContent?.title,
          partner: offerContent?.partner,
          partner_friendly_name: offerContent?.partner_friendly_name,
          variant: offerContent?.variant,
          category: offerContent?.category,
          subtitle: offerContent?.subtitle,
          component: offerContent?.component,
          highlights: offerContent?.highlights,
          default_limit: offerContent?.default_limit,
          image_logo: {
            filename: offerContent?.image_logo?.filename,
            alt: offerContent?.image_logo?.alt,
          },
          button_text: offerContent?.button_text,
          description: offerContent?.description,
          full_funnel: offerContent?.full_funnel,
          highlights_ac: offerContent?.highlights_ac,
          highlights_iq: offerContent?.highlights_iq,
          highlights_card_iq: offerContent?.highlights_card_iq,
          feature_information: offerContent?.feature_information,
          subject_to_credit_approval: offerContent?.subject_to_credit_approval,
          benefits: offerContent?.benefits,
          detail_page: offerContent?.detail_page,
          limit: offerContent?.limit,
          preApproved: offerContent?.pre_approved,
        },
      };

      formattedOffers.push(offer);
    }

    return formattedOffers;
  } catch (error) {
    console.error('[SH] Error getting offers from storyblok:', error);
    return [];
  }
}
