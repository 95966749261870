//TODO: remover esse arquivo apos deletar card antigo

import { SHOffer } from '@libs/sortinghat';

type HandleLimitMessageParams = {
  offer?: SHOffer | null;
  slug?: string;
  limit?: number;
  customMessage?: string;
};
function handleLimitMessage({
  offer,
  slug,
  customMessage,
  limit,
}: HandleLimitMessageParams): SHOffer | string | null {
  const acceptableDynamicLimit = 1000;

  const safeLimit = offer?.generalInfo?.limit || limit || 0;
  const safeSlug = offer?.content?.slug || slug;

  const cardHasLimit = Boolean(safeLimit);
  const limitInCurrency = `${new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(safeLimit)}`;

  const dynamicLimitMessage = !customMessage
    ? `Limite de R$ ${limitInCurrency}`
    : `${customMessage} ${limitInCurrency}`;

  const hasLowLimit = cardHasLimit && (safeLimit || 0) < acceptableDynamicLimit;
  const eligibleCards = ['cards-santander-free'];
  const useLowLimitTest = hasLowLimit && eligibleCards.includes(safeSlug || '');

  const lowLimitMessage = 'Limite até R$3.000';
  const limitMessage = useLowLimitTest ? lowLimitMessage : dynamicLimitMessage;

  if (limit && limit > 0) {
    return limitMessage;
  }

  if (!offer) return null;
  return {
    ...offer,
    generalInfo: {
      ...offer.generalInfo,
      limitMessage,
    },
  };
}

export function addLimitMessageOnOffer(offer: SHOffer, customMessage?: string) {
  if (!offer?.content?.slug) return null;
  return handleLimitMessage({ offer, customMessage }) as SHOffer;
}

export function getLimitMessage(
  slug: string,
  limit?: number,
  customMessage?: string
) {
  return handleLimitMessage({ slug, limit, customMessage }) as string;
}
