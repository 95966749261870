import { Spinetic, SpineticItem } from 'react-spinetic';
import { Text } from '@consumidor-positivo/aurora';
import { OfferFeaturedConfig } from './config';
import { Children, ReactNode } from 'react';
import { TypesSpinetic, TypesSpineticChangeEvent } from 'react-spinetic/types';

import './styles.scss';

type FeaturedOffersCarouselProps = {
  onChangeIndexCarousel?: (e: TypesSpineticChangeEvent) => void;
  children: ReactNode | ReactNode[];
};

export function FeaturedOffersCarousel({
  onChangeIndexCarousel,
  children,
}: FeaturedOffersCarouselProps) {

  /* FIXME: CustomChildrenDots: this prop has some rendering problem */
  const customDots = ({
    currentIndex,
    remainingIndexes,
  }: TypesSpinetic['CustomChildrenDots']) => {
    return (
      <Text variant="body-medium" weight="regular" color="secondary">
        Oferta{' '}
        <strong>
          {currentIndex + 1} de {remainingIndexes?.length}
        </strong>
      </Text>
    );
  };

  const wrappedChildren = Children.map(children, (child, index) => {
    return <SpineticItem key={index}>{child}</SpineticItem>;
  });

  return (
    <div className="offers-featured__carousel">
      <Spinetic
        config={OfferFeaturedConfig}
        change={onChangeIndexCarousel}
        //CustomChildrenDots={customDots}
      >
        {wrappedChildren}
      </Spinetic>
    </div>
  );
}
