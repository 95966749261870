import { SHOffer } from "../types";

export function getLimitMessage(offer: SHOffer) {

  const defaultLimit = offer?.content?.default_limit || '1.000'
  const safeLimit = offer?.generalInfo?.limit || 0;
  const limitInCurrency = `${new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(safeLimit)}`;

  const dynamicLimitMessage = `Limite de R$ ${limitInCurrency}`

  const hasLowLimit = Boolean(safeLimit && safeLimit > 0) && offer?.generalInfo?.lowLimit

  const limitMessage = hasLowLimit ? `Limite até R$ ${defaultLimit}` : dynamicLimitMessage;

  return {
    ...offer,
    generalInfo: {
      ...offer.generalInfo,
      limitMessage,
    },
  } as SHOffer;
}

