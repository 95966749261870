import {
  Card,
  Text,
  IconGift,
  Button,
  COLOR_INFO_50,
  Conditional,
} from '@consumidor-positivo/aurora';
import fireEmoji from '../../assets/icons/emoji-fire.svg';
import classNames from 'classnames';
import { AuroraCardOfferProps } from './types';
import './styles.scss';
import { formatHighlight } from '@hub/helpers/formatHighlight';

export default function AuroraCardOffer({
  onClickBtn,
  loading,
  offer,
  location,
  index,
  handleDetailRedirect,
  isModalCard,
  style,
}: AuroraCardOfferProps) {
  const { content, generalInfo } = offer;
  const {
    image_logo,
    category,
    title,
    highlights_ac,
    button_text,
    description,
    detail_page,
    partner_friendly_name,
    feature_information,
    partner,
  } = content;

  const {limit, preApproved, limitMessage } = generalInfo

  const offerEmphasis = [
    {
      title: category === 'loans' ? 'Valor' : 'Anuidade',
      description: formatHighlight({
        description: highlights_ac[0]?.description,
        descriptionSlug: highlights_ac[0].slug,
        partner,
        annuity: generalInfo.annuity,
      }),
    },
    {
      title: category === 'loans' ? 'Taxas' : 'Benefício',
      description: highlights_ac[1]?.description,
    },
  ];

  const cardClassNames = classNames('offer-card', {
    'with-limit': Boolean(limit),
  });

  const hasLimitOrApproval = limit || preApproved;
  const tagColor = hasLimitOrApproval ? 'primary' : 'secondary';
  const tagIcon = hasLimitOrApproval ? (
    <img src={fireEmoji} width={18} />
  ) : (
    <IconGift rawColor={COLOR_INFO_50} size="small" />
  );
  const tagText = hasLimitOrApproval
    ? 'Chance alta de aprovação'
    : feature_information;

  const showDetailButton =
    Boolean(detail_page) && Boolean(partner_friendly_name);

  return (
    <div className={cardClassNames} style={style}>
      <Card.Root maxWidth={308}>
        <Card.Container gap={16} alignItems="center">
          <Card.Tag color={tagColor} icon={tagIcon}>
            {tagText}
          </Card.Tag>
          <Conditional
            condition={!isModalCard}
            renderIf={
              <>
                <div className="au-card-offer__container-content">
                  <div className="au-card-offer__container-img">
                    <Card.Image height={72} src={image_logo?.filename} />
                  </div>

                  <Conditional
                    condition={Boolean(limit)}
                    renderIf={
                      <>
                        <Text variant="body-medium" weight="semibold">
                          {title}
                        </Text>
                        <Text variant="heading-small" weight="bold">
                          {limitMessage}
                        </Text>
                      </>
                    }
                    renderElse={
                      <Text variant="heading-micro" weight="bold">
                        {title}
                      </Text>
                    }
                  />
                </div>
                <Card.Emphasis height={111} content={offerEmphasis} />
                <div className='au-card-offer__container-btn'>
                  <Button
                    expand="x"
                    loading={loading}
                    onClick={() => onClickBtn(offer, location, index)}
                  >
                    {button_text}
                  </Button>
                  <Conditional
                    condition={showDetailButton}
                    renderIf={
                      <Button
                        expand="x"
                        type="outlined"
                        onClick={handleDetailRedirect}
                      >
                        Ver detalhes
                      </Button>
                    }
                  />
                </div>
              </>
            }
            renderElse={
              <>
                <Card.Container direction="row" alignItems="center" gap={16}>
                  <Card.Image height={64} src={image_logo?.filename} />
                  <Conditional
                    condition={Boolean(limit)}
                    renderIf={
                      <Card.Container>
                        <Text
                          variant="body-small"
                          color="secondary"
                          weight="semibold"
                        >
                          {title}
                        </Text>
                        <Text variant="heading-small" weight="bold">
                          {limitMessage}
                        </Text>
                      </Card.Container>
                    }
                    renderElse={
                      <Card.Container>
                        <Text variant="heading-micro" weight="bold">
                          {title}
                        </Text>
                        <Text variant="body-small" color="secondary">
                          {description}
                        </Text>
                      </Card.Container>
                    }
                  />
                </Card.Container>
                <Card.Container direction="row" alignItems="center" gap={8}>
                  <Conditional
                    condition={showDetailButton}
                    renderIf={
                      <Button
                        type="outlined"
                        expand="x"
                        onClick={handleDetailRedirect}
                      >
                        Ver detalhes
                      </Button>
                    }
                  />
                  <Button
                    onClick={() => onClickBtn(offer, location, index)}
                    loading={loading}
                    expand="x"
                  >
                    {button_text}
                  </Button>
                </Card.Container>
              </>
            }
          />
        </Card.Container>
      </Card.Root>
    </div>
  );
}
