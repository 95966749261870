const securitizingPartners: { [key: string]: string } = {
  ativossa: 'ativossa',
  'ativossa-nsd': 'ativossa-nsd',
  ipanema: 'ipanema',
  recovery: 'recovery',
  itapeva: 'itapeva',
  itapevaadhil: 'itapevaadhil',
  crediativos: 'crediativos',
  credigy: 'credigy',
  'fundo-atlantico': 'fundo-atlantico',
};

export default securitizingPartners;