import { SHA256 } from 'crypto-js';
import React, { ReactNode, createContext, useState } from 'react';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';

import { delay } from '../../../../utils/helpers/delay';
import { getDDD } from '../../../../utils/helpers/getDDD';
import { base64Encrypt } from '../../../../utils/helpers/base64';
import { isAndroid, isIOS } from '../../../../utils/helpers/mobileOS';

import { getRDG } from '../helpers/getRDG';
import { getCookie } from '../helpers/getCookies';
import { getFbAvailableCookies } from '../helpers/getFacebookPixel';
import { getGAAvailableCookies } from '../helpers/getGoogleAnalyticsCookies';

import useApiSortingHat from '../hooks/useApiSortingHat';

import {
  SHContextType,
  DecisionParams,
  TrackParams,
  SHReqProps,
  SafeUser,
  Identifier,
  HandlePayloadParam,
  SHOffer,
} from '../types';
import { getOffersFromDecide } from '../helpers/formatters';

const SHContext = createContext<SHContextType>({} as SHContextType);

interface SHProvider {
  children: ReactNode;
  mode: 'dev' | 'prod';
  debug?: boolean;
  appName: string;
  shKey?: string;
  baseURL?: string;
  maxCallNumber?: number;
  identifier?: Identifier;
}

const SHProvider: React.FC<SHProvider> = ({
  children,
  appName,
  mode,
  debug,
  shKey,
  baseURL,
  maxCallNumber,
  identifier = 'userId',
}) => {
  const { SHTrack, SHDecide } = useApiSortingHat({ baseURL, shKey });
  const numberOfCalls = maxCallNumber || 2;

  const [trackDone, setTrackDone] = useState(false);
  const [decideDone, setDecideDone] = useState(false);
  const [trackCount, setTrackCount] = useState(0);
  const [decideCount, setDecideCount] = useState(0);
  const [SHLoading, setSHLoading] = useState(true);
  const [SHError, setSHError] = useState(false);
  const [decisionsList, setDecisionsList] = useState<SHOffer[]>([]);

  const isAC = appName === 'ac';
  const device = isAndroid() ? 'android' : isIOS() ? 'ios' : 'web';
  const anonymousId = getAnonymousID();
  const sessionId = getSessionID();
  const workflow_id = isAC ? 'ac-tree-react' : 'cp-tree';

  const callSHTrack = async (params?: TrackParams) => {
    try {
      const shouldForceCall = params?.forceCall || false;
      if (trackCount === numberOfCalls && !shouldForceCall) return;

      const payload = handlePayload({
        user: params?.user,
        payloadFor: 'track',
        customIdentifier: params?.customIdentifier,
      });

      params?.decisionEnrichedEvent();
      if (!anonymousId || !sessionId) {
        await delay(5000);
        SHTrack(payload);
      } else {
        SHTrack(payload);
      }

      if (!params?.forceCall) {
        setTrackCount(trackCount + 1);
      }

      if (!trackDone) {
        setTrackDone(true);
      }
    } catch (err) {
      console.error('[SH_TRACK]', err);
    }
  };

  const callSHDecision = async (params?: DecisionParams) => {
    try {
      const shouldForceCall = params?.forceCall || false;
      if (decideCount === numberOfCalls && !shouldForceCall) return [];

      if (!params?.token && !params?.ignoreToken) {
        throw new Error('token not found');
      }

      const decideEncodedPayload = handlePayload({
        user: params?.user,
        payloadFor: 'decide',
        customIdentifier: params?.customIdentifier,
      });

      params?.decisionRequestEvent();

      await delay(params?.delayTime || 1000);
      const response = await SHDecide({
        data: decideEncodedPayload.data,
        token: params?.token,
      });

      const offers = getOffersFromDecide(response?.data?.result?.decisions);

      const hasDebug = getCookie('DEBUG_SH');
      if ((mode === 'dev' && debug) || hasDebug) {
        console.log('[SH] [callSHDecision] [---START_LOG---]');
        console.log('[PARAMS]: ', params);
        console.log('[API_RESPONSE]:', response);
        console.log(
          '[SH] [RESPONSE_COUNT]: ',
          response?.data?.result?.decisions?.length
        );
        console.log('[FORMATTED_OFFERS]:', offers);
        console.log('[SH] [OFFERS_COUNT]: ', offers?.length);
        console.log('[SH] [callSHDecision] [---END_LOG---]');
      }

      if (offers?.length < 1) {
        throw new Error('No content found in SH decide');
      }

      const decisionOutcomeEventPayload = offers?.map((result) => {
        return {
          productCategory: result?.generalInfo?.category,
          productId: result?.generalInfo?.slug,
        };
      });

      params?.decisionOutcomeReceivedEvent(decisionOutcomeEventPayload);

      setDecisionsList(offers);
      if (!shouldForceCall) {
        setDecideCount(decideCount + 1);
      }

      setSHError(false);
      return offers;
    } catch (err) {
      console.error('[SH_DECISION]: ', err);
      setSHError(true);
      return [];
    } finally {
      setSHLoading(false);
      setDecideDone(true);
    }
  };

  function handlePayload({
    user,
    customIdentifier,
    payloadFor,
  }: HandlePayloadParam) {
    const safeUser: SafeUser = {
      document: user?.documento || user?.document || '',
      phone: user?.celular || user?.phone || '',
      birthdate: user?.dataNascimento || user?.birthdate || '',
      full_name: user?.nome || user?.full_name || '',
      email: user?.email || '',
      uuid: user?.uuid || '',
      created_at: user?.created_at || user?.createdAt || '',
    };

    const identifierKeys = {
      userId: safeUser?.uuid,
      userDocument: SHA256(safeUser.document).toString(),
      anonymousId,
      custom: customIdentifier,
    };

    const identifierValue = identifierKeys[identifier];

    const payload: Partial<SHReqProps> = {
      anonymousId,
      document: safeUser?.document,
      cpf: safeUser?.document,
      device,
      workflow_id,
      identifier: identifierValue,
    };

    if (payloadFor === 'track') {
      payload.mainPhone = safeUser?.phone;
      payload.fullName = safeUser?.full_name;
      payload.email = safeUser?.email;
      payload.birthdate = safeUser?.birthdate;
      payload.sessionId = getSessionID();
      payload.fbCookies = getFbAvailableCookies();
      payload.gaCookies = getGAAvailableCookies();
      payload.ddd = getDDD(safeUser?.phone || '');
      payload.rdg = getRDG(appName, safeUser?.document || '');
      payload.sourceUrl =
        getCookie('start_source_url') || window?.location.href;
      payload.created_at = safeUser?.created_at;
    }

    const hasDebug = getCookie('DEBUG_SH');
    if ((mode === 'dev' && debug) || hasDebug) {
      console.log('[SH] [handlePayload] [---START_LOG---]');
      console.log('[param.user]:', user);
      console.log('[param.payloadFor]:', payloadFor);
      console.log('[param.customIdentifier]:', customIdentifier);
      console.log('[payload]:', payload);
      console.log('[SH] [handlePayload] [---END_LOG---]');
    }

    const encodedPayload = base64Encrypt(JSON.stringify(payload));
    return { data: encodedPayload };
  }

  const resetContext = () => {
    setTrackDone(false);
    setDecideDone(false);
    setTrackCount(0);
    setDecideCount(0);
    setSHLoading(true);
    setDecisionsList([]);
  };

  return (
    <SHContext.Provider
      value={{
        callSHDecision,
        callSHTrack,
        decisionsList,
        trackDone,
        decideDone,
        SHLoading,
        SHError,
        resetContext,
      }}
    >
      {children}
    </SHContext.Provider>
  );
};

export { SHProvider, SHContext };
