import { useImperativeHandle, useState } from 'react';

import { getInfosCielo, TypesCieloInfos } from '@common/hooks/useDebts/helpers';

import { SortingDebtResult } from '@common/apis/sorting-debt/types';

import { dateToLocale } from '@libs/utils/helpers/dateToLocale';
import { currencyToLocale } from '@libs/utils/helpers/currencyToLocale';

import * as Types from '../types';
import { useFormattedCetProps } from './useFormattedCetProps';

import { useRedline } from '@libs/redline';

const titleMap: Types.TitleMapProps = {
  product: 'Produto',
  produto: 'Produto',

  contract: 'Contrato',
  contrato: 'Contrato',

  originalDebtDate: 'Vencimento original',
  dataOriginalDivida: 'Vencimento original',

  originalValue: 'Valor original',
  valorOriginal: 'Valor original',

  percentualJuros: 'Acréscimos/Correções',
  interestPercentage: 'Acréscimos/Correções',

  source: 'Origem',
  origem: 'Origem',

  description: 'Descrição',
  descricao: 'Descrição'
};

const useDetails = (ref: React.ForwardedRef<Types.DetailsModalRef>): any => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { track: trackRedline } = useRedline();

  const [currentDebt, setCurrentDebt] = useState<SortingDebtResult | null | any>(null);
  const [currentPaymentOption, setCurrentPaymentOption] = useState<any>({})
  const [debtDetails, setDebtDetails] = useState<any>([]);
  const [infosCielo, setInfosCielo] = useState<TypesCieloInfos>({} as any);

  const [infosModal, setInfosModal] = useState({
    hasDebtComposition: false,
    title: 'Detalhes do acordo',
    subTitle: ""
  })

  const [buttonText, setButtonText] = useState<string>();
  const [closeModalOnClickCta, setCloseModalOnClickCta] = useState<boolean | undefined>(false);

  const [isUnrecognizedDebtModalOpen, setIsUnrecognizedDebtModalOpen] = useState<boolean>(false);

  const [clickCta, setClickCta] = useState<(() => void) | null>(null);

  const handleClose = () => {
    setIsOpen(false);
    setCurrentDebt(null);
    setClickCta(null);
    setIsUnrecognizedDebtModalOpen(false);
  };

  const handleClick = () => {
    if (clickCta) {
      clickCta();
    }

    if (closeModalOnClickCta) setIsOpen(false);
  };

  const openUnrecognizedDebtModal = () => {
    setIsUnrecognizedDebtModalOpen(true);
    setIsOpen(false);

    trackRedline.userTracking.elementClicked({
      location: window.location.pathname,
      elementType: 'link',
      name: `unrecognized-debt-modal-${currentDebt?.partner?.identifier}`,
      text: 'Não reconheço essa dívida',
    });
  }

  const dismissUnrecognizedDebtModal = () => {
    setIsUnrecognizedDebtModalOpen(false);
    setIsOpen(true);
  }

  useImperativeHandle(ref, () => ({
    open({ debt, paymentOption, onClickNegotiate, buttonText = "Negociar", closeModalOnClickCta }) {
      setIsOpen(true);

      setCurrentDebt(debt);
      setCurrentPaymentOption(paymentOption);
      setDebtDetails(debt?.details || debt?.detalhes);

      const isCielo = debt?.partner?.identifier === "cielo";
      setInfosCielo(isCielo ? getInfosCielo(debt?.properties || debt?.propriedades) : {});

      const hasDebtComposition = debt?.detalhes?.length > 1 || debt?.details?.length > 1;
      setInfosModal({
        hasDebtComposition,
        title: hasDebtComposition ? 'Composição da dívida' : 'Detalhes da dívida',
        subTitle: hasDebtComposition ? 'Essa dívida é composta por mais de um produto/contrato.' : ""
      })

      setButtonText(buttonText);
      setCloseModalOnClickCta(closeModalOnClickCta);

      setClickCta(() => onClickNegotiate);
    },
    loading(loading) {
      setIsLoading(loading);
    }
  }));

  const identifyKeysDetails = (key: string, value: string | number) => {
    const isOriginalDebtDate = key === 'originalDebtDate' || key === 'dataOriginalDivida';
    if (isOriginalDebtDate) return dateToLocale(value.toString());

    const isOriginalValue = key === 'originalValue' || key === 'valorOriginal';
    if (isOriginalValue && typeof value === 'number') return currencyToLocale(value);

    const isInterestPercentage = key === 'interestPercentage' || key === 'percentualJuros';
    if (isInterestPercentage && typeof value === 'number') {
      const formattedPercentage = (value * 100).toFixed(2);

      return `${formattedPercentage}%`
    }

    return value
  }

  const handleTitleMap = (currentDebt: SortingDebtResult) => {
    const isBradesco = currentDebt?.partner?.partnerIdentifier === "bradesco";
    const modifiedTitleMap = { ...titleMap };

    if (isBradesco) {
      delete modifiedTitleMap.descricao;
      delete modifiedTitleMap.description;
    }

    return modifiedTitleMap;
  }

  return {
    isOpen,
    isLoading,
    currentDebt,
    formattedCetProps: useFormattedCetProps(currentPaymentOption),
    debtDetails,
    infosCielo,
    buttonText,
    handleClick,
    handleClose,
    openUnrecognizedDebtModal,
    dismissUnrecognizedDebtModal,
    isUnrecognizedDebtModalOpen,
    titleMap: handleTitleMap(currentDebt),
    identifyKeysDetails,
    infosModal
  };
};

export default useDetails;
