const constantsDebts = {
  DEBT: 'DEBT',
  PAYMENT_OPTION: 'PAYMENT_OPTION',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  PRESELECTED_PAYMENT_OPTION: 'PRESELECTED_PAYMENT_OPTION',

  SORTING_DEBTS_RES: 'SORTING_DEBTS_RES',
  AGREEMENTS_RES: 'AGREEMENTS_RES',

  COUNTDOWN_STARTED_AT: 'COUNTDOWN_STARTED_AT',

  CLOSED_AGREEMENT: 'CLOSED_AGREEMENT',
  PIX_STATUS: 'PIX_STATUS_REACT', // TEMP

  BILLET_NOT_FOUND: 'BILLET_NOT_FOUND',

  PROPERTIES: {
    COMPLEMETARY_INFO: 'INFORMACAO_COMPLEMENTAR',
    DEBIT_ACCOUNT: 'DEBITO_CONTA',
    DEBIT_INFO: 'INFORMACOES_DEBITO',
    ENTRY: 'ENTRADA',
    PAYMENT_DATE: 'PAYMENT_DATE',

    FIRST_INSTALLMENT_VALUE: 'FIRST_INSTALLMENT_VALUE',
    CHANGE_MODALITY_AVAILABLE: 'CHANGE_MODALITY_AVAILABLE',

    CET_KEYS: [
      'PERCENTUAL_CUSTO_EFETIVO_MES',
      'PERCENTUAL_CUSTO_EFETIVO_ANO',
      'TAXA_JUROS',
      'INTEREST_RATE',
      'TAXA_JUROS_MENSAL',
      'TAXA_JUROS_ANUAL',
      'VALOR_PARCELA_APOS_VENCIMENTO',
      'CUSTO_EFETIVO_PROPOSTA',
      'VALOR_IOF',
      'PERCENTUAL_IOF',
    ],

    AVAILABLE_GROUP_CONTRACTS: 'AVAILABLE_GROUP_CONTRACTS',
    ITAU_NEGOTIATION_TYPE: 'ITAU_NEGOTIATION_TYPE',
    ITAU_NEGOTIATION_TYPE_VALUE: 'OVERDUE',

    CONTRACTS_INFO: 'INFORMACOES_CONTRATOS',
  },

  LIST_OF_PAYMENT_METHODS: [
    {
      id: 'BILLET',
      name: 'Boleto',
    },
    {
      id: 'PIX',
      name: 'Pix',
    },
    {
      id: 'DEBIT_ACCOUNT',
      name: 'Débito em conta',
    },
  ],

  PARTNERS_WITH_SINGLE_PAY_METHOD: ['carrefour', 'itau'],
  PARTNERS_WHO_CAN_UPDATE_AGREEMENT: ['bancodobrasil', 'itau'],
};

export default constantsDebts;
