import { useEffect, useImperativeHandle, useState } from 'react';

import { SimulatedDebt } from '@common/apis/simulated-debt/types';
import constants from '@common/constants';
import { legacyBase64Encrypt } from '@libs/utils/helpers/base64';
import { currencyToLocale } from "@libs/utils/helpers/currencyToLocale";
import { useRedline } from '@libs/redline';

interface OpenProps {
  debt: SimulatedDebt;
  goToInstallments: (paymentMethod: string) => void;
}

export interface AbatementModalRef {
  open: (args: OpenProps) => void;
}

const useAbatement = (ref: React.ForwardedRef<AbatementModalRef>) => {
  const [currentDebt, setCurrentDebt] = useState<SimulatedDebt | null>(null);
  const { track } = useRedline()
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [goToInstallments, setGoToInstallments] = useState<
    ((paymentMethod: string) => void) | null
  >(null);

  const [withAbatementList, setWithAbatementList] = useState<any[]>(
    []
  );
  const [withoutAbatementList, setWithoutAbatementList] = useState<
    any[]
  >([]);


  const checkOptionsWithRebates = (debt: SimulatedDebt) => {
    const paymentOptions = debt?.opcaoPagamento;

    const withAbatement = paymentOptions?.filter(
      (option) => option?.percentualDesconto > 0
    );

    const withoutAbatement = paymentOptions?.filter(
      (option) => option?.percentualDesconto === 0
    );

    setWithAbatementList(withAbatement);
    setWithoutAbatementList(withoutAbatement);
  };

  const valueWithAbatement = currencyToLocale(withAbatementList[0]?.valorAcordo);
  const valueWithoutAbatement = currencyToLocale(withoutAbatementList[0]?.valorAcordo);

  const displayDiscountValue = () => {
    return withAbatementList[0]?.percentualDesconto === 1 ? 0.99 : withAbatementList[0]?.percentualDesconto;
  };

  const hasDiscount = displayDiscountValue() >= 0.1;
  const discountPercentage = `${Math.round(displayDiscountValue() * 100)}%`;

  const addDebtToLocalStorage = (selectedOption: string) => {
    if (currentDebt) {
      const isWithAbatement = selectedOption === 'with_abatement';
      const filteredPaymentOptions =
        selectedOption === 'with_abatement'
          ? withAbatementList
          : withoutAbatementList;
      const filteredDebt: SimulatedDebt = {
        ...currentDebt,
        opcaoPagamento: filteredPaymentOptions,
      };

      const encryptedDebt = legacyBase64Encrypt(
        JSON.stringify({
          ...filteredDebt,
          expTime: new Date(),
          ...(isWithAbatement && {
            typeSelected: selectedOption,
          }),
        })
      );

      const encryptedPaymentOption = legacyBase64Encrypt(
        JSON.stringify(filteredDebt.opcaoPagamento[0])
      );

      localStorage.setItem(constants.debts.DEBT, encryptedDebt);

      localStorage.setItem(
        constants.debts.PAYMENT_OPTION,
        encryptedPaymentOption
      );
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setCurrentDebt(null);
    setGoToInstallments(null);
  };

  const handleClickOption = (option: string) => {
    handleClose();

    setTimeout(() => {
      addDebtToLocalStorage(option);
      if (currentDebt && goToInstallments) {
        const [paymentOption] = currentDebt.opcaoPagamento;
        goToInstallments(paymentOption.paymentMethodName);
      }

    }, 100);
  };



  useEffect(() => {
    if (currentDebt) {
      checkOptionsWithRebates(currentDebt);
    }
  }, [currentDebt]);

  useImperativeHandle(ref, () => ({
    open({ debt, goToInstallments }) {
      setCurrentDebt(debt);
      setIsOpen(true);
      track.userTracking.elementViewed({
        name: 'Modal abatimento',
      })
      setGoToInstallments(() => goToInstallments);
    },
  }));

  return {
    withAbatement: withAbatementList?.length > 0,
    withoutAbatement: withoutAbatementList?.length > 0,
    valueWithAbatement,
    valueWithoutAbatement,
    hasDiscount,
    discountPercentage,
    isOpen,
    handleClickOption,
    handleClose,
  };
};

export default useAbatement;
