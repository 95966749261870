import { SHOffer } from '@libs/sortinghat';
import { useState } from 'react';
import { TypesSpineticChangeEvent } from 'react-spinetic/types';

export function useOfferFeatured(offers: SHOffer[]) {
  const [changeCarousel, setChangeCarousel] =
    useState<TypesSpineticChangeEvent>();

  function onChangeIndexCarousel(e: TypesSpineticChangeEvent) {
    setChangeCarousel(e);
  }

  return {
    changeCarousel,
    onChangeIndexCarousel,
  };
}
